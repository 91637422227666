import CookieAuth from './cookieAuth';
import LocalhostAuth from './localhostAuth';
import { isDevMode } from '../utils/envConfig';

export { AuthInterface } from './interfaces';

const Auth = isDevMode ? LocalhostAuth : CookieAuth;

export default Auth;

export { LocalhostAuth, CookieAuth };
