type refreshRequestProps = {
  clientId: string;
  refreshToken: string;
};

export type RefreshRequestResponseModel = {
  AuthenticationResult: {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    TokenType: string;
  };
  ChallengeParameters: any;
};

const ENDPOINT_URL = 'https://cognito-idp.ap-southeast-2.amazonaws.com/';

const refreshRequest = async (props: refreshRequestProps): Promise<any> => {
  const body = {
    ClientId: props.clientId,
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    AuthParameters: {
      REFRESH_TOKEN: props.refreshToken,
    },
  };

  try {
    const res = await fetch(ENDPOINT_URL, {
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        'x-amz-user-agent': 'aws-amplify/5.0.4 js',
      },
      body: JSON.stringify(body),
      method: 'POST',
    });

    return res.json();
  } catch (error) {
    console.log(`Error with refreshing: ${error}`);
  }
};

export default refreshRequest;
