import Cookies from 'js-cookie';

import { parseCognitoUserData } from '../utils/cognito/parseCognitoUserData';
import {
  IdTokenPattern,
  UserDataPattern,
} from '../utils/cookie/cognitoCookiePatterns';
import { refreshSession } from '../utils/cookie/refreshSession';
import { AuthInterface } from './interfaces';
import cookieStorageConfig from '../utils/cognito/cookieStorageConfig';

export const findCookieMatchingPattern = (pattern: string) => {
  const allCookies = Cookies.get();
  return Object.entries(allCookies).find(([key, _value]) => key.match(pattern));
};

const CookieAuth: AuthInterface = {
  currentSession: () => {
    return {
      getIdToken: () => {
        //Refreshing everytime a request is made, for now.
        refreshSession();
        const idTokenCookie = findCookieMatchingPattern(IdTokenPattern);
        return idTokenCookie ? idTokenCookie[1] : undefined;
      },
    };
  },

  currentAuthenticatedUser: () => {
    const userDataCookie = findCookieMatchingPattern(UserDataPattern);
    return userDataCookie ? parseCognitoUserData(userDataCookie[1]) : undefined;
  },

  signOut: env => {
    let allCookies = Cookies.get();
    const cookieConfig = cookieStorageConfig({ env });

    for (const [key] of Object.entries(allCookies)) {
      document.cookie = `${encodeURIComponent(key)}=;domain=${
        cookieConfig.domain
      };path=${cookieConfig.path};max-age=0`;
    }
  },
};

export default CookieAuth;
