import { Environment } from '../../types';

const DEFAULT_EXPIRY_IN_DAYS = 10;

type CookieStorageConfigOptions = {
  env?: Environment;
  expiryInDays?: number;
};

const cookieStorageConfig = (options?: CookieStorageConfigOptions) => {
  const domain = options?.env == null ? 'localhost' : '.halter.io';
  const expires = options?.expiryInDays || DEFAULT_EXPIRY_IN_DAYS;
  const secure = options?.env != null;

  return {
    domain,
    path: '/',
    expires,
    sameSite: 'strict',
    secure,
  };
};

export default cookieStorageConfig;
