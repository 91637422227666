import { parseCognitoUserData } from '../utils/cognito/parseCognitoUserData';
import { AuthInterface } from './interfaces';
import { idToken, userData } from '../utils/envConfig';

const LocalhostAuth: AuthInterface = {
  currentSession: () => {
    return {
      getIdToken: () => {
        return idToken;
      },
    };
  },

  currentAuthenticatedUser: () => {
    return parseCognitoUserData(userData);
  },

  signOut: () => {
    // Do nothing...
  },
};

export default LocalhostAuth;
