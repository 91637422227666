import Cookies from 'js-cookie';
import { findCookieMatchingPattern } from '../../auth/cookieAuth';
import {
  AccessTokenPattern,
  IdTokenPattern,
  RefreshTokenPattern,
} from './cognitoCookiePatterns';
import refreshRequest, { RefreshRequestResponseModel } from './refreshRequest';
import cookieStorageConfig from '../cognito/cookieStorageConfig';

const getClientId = (cookieName: string): string => {
  // takes in a cookie name and extracts the client ID out (Client ID is in the 2nd part of a cognito cookies name.)
  const sections = cookieName.split('.');
  return sections[1];
};

export const refreshSession = () => {
  const refreshToken = findCookieMatchingPattern(RefreshTokenPattern);
  const cookieConfig = cookieStorageConfig();

  if (refreshToken) {
    const clientId = getClientId(refreshToken[0]);
    refreshRequest({ refreshToken: refreshToken[1], clientId: clientId }).then(
      res => {
        const data = res as RefreshRequestResponseModel; // Assert

        const idTokenCookie = findCookieMatchingPattern(IdTokenPattern);
        const accessTokenCookie = findCookieMatchingPattern(AccessTokenPattern);

        // Replace idToken and accessToken cookie, preserving the ORIGINAL name
        if (accessTokenCookie) {
          Cookies.set(
            accessTokenCookie[0],
            data.AuthenticationResult.AccessToken,
            {
              ...cookieConfig,
              sameSite: 'strict',
            }
          );
        }
        if (idTokenCookie) {
          Cookies.set(idTokenCookie[0], data.AuthenticationResult.IdToken, {
            ...cookieConfig,
            sameSite: 'strict',
          });
        }
      }
    );
  }
};
