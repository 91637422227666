import { UserData } from '../../types';

type CognitoUserData = {
  Username: string;
  UserAttributes: {
    Name: string;
    Value: string;
  }[];
};

export const parseCognitoUserData = (serializedData: string): UserData => {
  const userData: CognitoUserData = JSON.parse(
    decodeURIComponent(serializedData)
  );

  return userData.UserAttributes.reduce(
    (result, attr) => Object.assign(result, { [attr.Name]: attr.Value }),
    {}
  );
};
